import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Layout from "../components/layout"
import SubmissionForm from "../components/submission-form";


function getYear() {
  return new Date().getFullYear();
}

export default function Submissions({ data }) {
  return (
    <>
      <Layout>
        <SEO title="Artist Submissions | Ballteam Playlists" keywords={[`music`, `rap`, `hip-hop`, `playlists`, `spotify`, `apple music`, `austin`, `texas`]} />
        <div className="albums row">
          <div className="col-lg-10" style={{ margin: 'auto' }}>
            <div className="row">
              <div className="col-12">
                <a className="nav-link" href="/" style={{ marginBottom: '3rem', display: 'inline-block', width: 'auto' }}>Back to main page</a>
                <SubmissionForm />
              </div>
            </div>
          </div>
        </div>
      </Layout>
      <footer>
        <p className="small" style={{ textAlign: 'center' }}>&copy; Ballteam Playlists {getYear()}</p>
      </footer>
    </>
  )
}

// export const query = graphql`
//   {
//     logo: file(relativePath: { eq: "ballteam-white.png" }) {
//       childImageSharp {
//         fixed(width: 300) {
//           ...GatsbyImageSharpFixed
//         }
//       }
//     }
// `