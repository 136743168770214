import React, { useState } from 'react';
import { navigate } from "gatsby"
import Recaptcha from 'react-google-recaptcha'

import UseForm from '../hooks/useForm';

import '../scss/form.scss';

const RECAPTCHA_KEY = process.env.GATSBY_RECAPTCHA_SITE_KEY;

if (typeof RECAPTCHA_KEY === 'undefined') {
  throw new Error(`
  Env var GATSBY_APP_SITE_RECAPTCHA_KEY is undefined! 
  You probably forget to set it in your Netlify build environment variables. 
  Make sure to get a Recaptcha key at https://www.netlify.com/docs/form-handling/#custom-recaptcha-2-with-your-own-settings
  Note this demo is specifically for Recaptcha v2
  `)
}

export default function SubmissionForm() {

  const [formError, setFormError] = useState(false);
  const [formLoading, setFormLoading] = useState(false);

  const recaptchaRef = React.createRef()

  const encode = (data) => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  }

  const submitForm = () => {
    const recaptchaValue = recaptchaRef.current.getValue();
    setFormLoading(true);
    fetch('/', {
      method: 'POST',
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ 
        "form-name": "submissionForm", ...inputs,
        "g-recaptcha-response": recaptchaValue,
      })
    }).then(
      (response) => {
        setFormLoading(false);
        if (response.ok) {
          navigate("/thank-you/")        
        } else {
          throw new Error('Something went wrong');
        }
      })
      .catch((error) => {
        setFormError(true);
        window.scrollTo(0, 0);
        console.log(error);
      })
  }

  const { inputs, handleInputChange, handleSubmit } = UseForm(submitForm);

  return (
    <form data-netlify="true" name="submissionForm" method="post" id="submissionForm"  data-netlify-recaptcha="true" data-netlify-honeypot="bot-field" onSubmit={handleSubmit}>
      <input type="hidden" name="form-name" value="submissionForm" />
      <h2>Artist Submissions</h2>
      <p style={{ marginTop: "15px" }}>Please fill out the following fields.</p>
      <p className="small">NOTE: We are only accepting Texas based artists at this time.</p>
      { formError === true && 
        <div className="error" role="alert">
          Sorry, something went wrong. Please try again.
        </div>
      }
      <div style={{ marginBottom: '2rem' }}>
        <p className="hidden">
          <label>Don’t fill this out if you’re human: <input name="bot-field" /></label>
        </p>
        <label className="form-label required">Artist Name</label>
        <input className="form-control" type="text" name="artistName" onChange={handleInputChange} value={inputs.artistName || ''} required />
        <label className="form-label required">Email</label>
        <input className="form-control" type="email" name="email" onChange={handleInputChange} value={inputs.email || ''} required />
        <div id="emailHelp" className="form-text">We won't share your email. This is only used if we have a question regarding your submission.</div>
        <label className="form-label required">City</label>
        <input className="form-control" type="text" name="city" onChange={handleInputChange} value={inputs.city || ''} required />
        <label className="form-label required">Spotify Artist URL</label>
        <input className="form-control" type="url" name="spotifyURL" onChange={handleInputChange} value={inputs.spotifyURL || ''} required />
        <div id="spotifyHelp" className="form-text">Place the Spotify Artist link here.</div>
        <label className="form-label">Twitter Profile URL</label>
        <input className="form-control" type="url" name="twitterURL" onChange={handleInputChange} value={inputs.twitterURL || ''} />
        <div id="twitterHelp" className="form-text">Place your Twitter profile link here.</div>
        <label className="form-label">Instagram Profile URL</label>
        <input className="form-control" type="url" name="instagramURL" onChange={handleInputChange} value={inputs.instagramURL || ''} />
        <div id="instagramHelp" className="form-text">Place your Instagram profile link here.</div>
        <Recaptcha className="form-label" ref={recaptchaRef} sitekey={RECAPTCHA_KEY} />
      </div>
      <button className="btn btn-primary" type="submit" disabled={ formLoading }>
        { formLoading === true ? `Submitting...` : `Submit` }
      </button>
    </form>
  )
}